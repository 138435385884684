
export default {
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
        country: {
            type: String,
            default: "",
        },
        storeId: {
            type: String,
            default: "",
        },
        storeName: {
            type: String,
            default: "",
        },
    },
}